import React from 'react'
import { useStaticQuery, graphql, Link as GLink } from 'gatsby'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import { Box, Text, Card, Flex, Button } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Section from '@components/Section'
import Seo from '@widgets/Seo'

/**
 * Shadow me to add your own content
 */
const styles = {
    imageWrapper: {
        borderRadius: `default`,
        overflow: `hidden`,
        position: `relative`
    },
    button: {
        display: [`none`, `block`],
        position: `absolute`,
        bottom: 4,
        right: 4
    },
    grid: {
        flexWrap: [`wrap`, null, `nowrap`],
        ' > div + div': {
            ml: [0, 0, 5]
        }
    },
    column: {
        flex: `auto`,
        flexBasis: [`full`, null, `1/2`]
    }
}


const About = (props) => {
    const data = useStaticQuery(aboutQuery)
    const image = getImage(data.avatar)
    const subheader = 'J\'aide les équipes exécutives et techniques à trouver des solutions.'

    return (
        <Layout {...props}>
            <Seo title='À propos' />
            <Divider />
            <Stack>
                <Main>
                    <PageTitle
                        header='À propos'
                        subheader={subheader}
                    />
                    <Divider />
                    <Box sx={styles.imageWrapper}>
                        <Img image={image} />
                        <Button as={GLink} to='/contact' sx={styles.button}>
                            Contactez-moi
                        </Button>
                    </Box>
                    <Divider />
                    <Flex sx={styles.grid}>
                        <Box sx={styles.column}>
                            <Section title='Je suis Franck Anso'>
                                <Text variant='p'>
                                    Avec une expérience à la fois en management et en développement, j'aide à rationaliser les processus et à constituer des équipes techniques heureuses et productives.
                                </Text>
                                <Text variant='p'>
                                    En tant que CTO hands-on et servant leader, j'aime autant développer qu'améliorer les compétences des équipes.
                                </Text>
                                <Text variant='p'>
                                    Mon but premier est toujours d'améliorer le produit du point de vue du client.
                                </Text>
                            </Section>
                            <Divider />
                            <Section title='Senioradom'>
                                <Text variant='p'>
                                    Directeur technique et membre du CODIR de Senioradom depuis plus de 7 ans, j'ai participé à la mise en place d'offres en lien avec la téléassistance et l'apprentissage des habitudes de vie des personnes.
                                </Text>
                                <Text variant='p'>
                                    Java 17, Spring, MongoDB Atlas, InfluxDB, Serverless, IoT, Twilio
                                </Text>
                            </Section>
                            <Divider />
                            <Section title='FidUp'>
                                <Text variant='p'>
                                    J'ai monté une startup dans le domaine de la gamification.
                                </Text>
                                <Text variant='p'>
                                    Le but était d'amplifier les interactions entre les visiteurs et les sites web en appliquant les mécaniques et signaux propres aux jeux.
                                </Text>
                            </Section>
                        </Box>
                        <Box sx={styles.column}>
                            <Section title='CTO hands-on'>
                                <Card variant='paper'>
                                    Je peux vous aider à démarrer votre nouveau projet ou remplacer temporairement votre CTO sur le départ,
                                    vous aiguiller sur les bons choix et développer à vos côtés sur les dernières technologies.
                                </Card>
                            </Section>
                            <Divider />
                            <Section title='Coaching'>
                                <Card variant='paper'>
                                    Je peux vous aider à former vos leaders techniques, à trouver
                                    les méthodologies qui vous conviennent, vous épauler dans vos
                                    recrutements et accompagner le changement
                                    pour que votre société soit agile et dynamique.
                                </Card>
                            </Section>
                            <Divider />
                            <Section title='Audit'>
                                <Card variant='paper'>
                                    Je peux analyser votre service IT pour vous donner des
                                    recommandations afin d'améliorer l'expérience développeur interne
                                    et externe que vous proposez.
                                </Card>
                            </Section>
                        </Box>
                    </Flex>
                </Main>
            </Stack>
        </Layout>
    )
}

const aboutQuery = graphql`
  query AboutQuery {
    avatar: file(absolutePath: { regex: "/about.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1140
          height: 500
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
  }
`

export default About;
